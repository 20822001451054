@import "style/colors.less";

.container {
  position: relative;
  background: @grey-50;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.logo {
  position: absolute;
  left: 20px;
  top: 20px;
}

.closeButton {
  position: absolute;
  right: 12px;
  top: 12px;
}

.icon {
  width: 80px;
  height: 80px;
  font-size: 30px;
  background: @primary-500;
  color: @white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  margin-bottom: 24px;
  margin-top: 24px;
}

.bodyText {
  margin: 8px 20px 12px 20px;
  color: @grey-700;
  max-width: 850px;
}

.divider {
  width: 100px;
  border-bottom: 1px solid @black;
  margin-bottom: 42px;
}

.infoBox {
  display: flex;
  margin-bottom: 28px;

  .infoBoxIcon {
    color: @white;
    background: @primary-500;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
  }
  .infoBoxText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 420px;
    margin-left: 12px;
  }
  .infoBoxBody {
    margin-top: 8px;
    text-align: left;
  }
}
