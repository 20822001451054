@import "../../../style/typography.less";
.header {
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  align-items: center;
  border-bottom: 1px solid @grey-100;
  .inter-medium();
  flex-shrink: 0;
}

.title {
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subtitle {
  padding-left: 8px;
  font-size: 11px;
  line-height: 16px;
}

.headerAction {
  margin-left: auto;
}

.secondary {
  padding: 12px;
  margin: -12px;
  margin-bottom: 12px;
  border-bottom: none;
}
