@import "style/colors.less";

.popup {
  width: 460px;
}
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.popupContent {
  flex: 1 1;
  overflow: auto;
  margin-right: -12px;
  padding-right: 12px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.dropdown {
  margin-bottom: 12px;
}

.preview {
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;

  .invoiceContainer {
    display: flex;
    flex-direction: column;
    margin: 0 24px 24px 0;
  }

  .firstInvoices {
    display: flex;
    flex-direction: row;
  }

  .firstInvoice {
    margin-right: 24px;
  }

  .billingPeriod {
    display: flex;
    justify-content: center;
    background: @grey-50;
    border-radius: 16px;
    margin-top: 8px;
    h6 {
      color: @grey-700;
    }
    &.active {
      background: @primary-100;
    }
  }
  .prevRamp {
    opacity: 0.5;
  }

  .invoice {
    width: 116px;
    height: 155px;
    position: relative;

    svg {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }

    .content {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      text-align: center;
      padding: 8px;
      padding-top: 24px;

      .bottom {
        //position: absolute;
        text-align: center;
        //left: 0;
        //right: 0;
        //bottom: 0px;
      }
    }
  }
}
.actions {
  margin-top: auto;
  align-self: flex-end;
}

.tooltip {
  color: @white;
}
.tooltipContainer {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
