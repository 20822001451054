.container {
  display: flex;
  align-items: center;
}

.input {
  width: 100%;
}

.grantsText {
  margin-left: -152px;
  padding-top: 14px;
  z-index: 0;
}
