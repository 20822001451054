@import "style/colors.less";
@import "style/border-radius.less";

.popup {
  width: 460px;

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
    }
    .popupContent {
      flex: 1 1;
      overflow: auto;
      margin-right: -12px;
      padding-right: 12px;

      .text {
        margin-bottom: 10px;

        .warning {
          color: @warning-500;
          font-size: 16px;
          vertical-align: top;
          margin-right: 4px;
        }
      }

      .selectButtons {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .selectAll,
        .deselectAll {
          margin-bottom: 4px;
        }
      }

      .charges {
        padding: 8px;
        background-color: @grey-50;
        border: 1px solid @grey-100;
        .border-radius-large();
        margin-bottom: 12px;

        .productName {
          color: @grey-700;
          margin-bottom: 4px;
        }

        .selected {
          color: @primary-600;
        }
        .deselected {
          color: @grey-200;
        }
      }
    }

    .actions {
      margin-top: auto;
      align-self: flex-end;
    }
  }
}
