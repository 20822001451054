@import "style/colors.less";
@import "style/border-radius.less";

.table {
  border: 1px solid @grey-100;
  .border-radius-large();

  table {
    margin: 0;
    min-width: 100%;
  }
}
