@import "style/border-radius.less";
@import "style/colors.less";

.container {
  border: 1px solid @grey-100;
  .border-radius-large();
  background: @white;
  margin-bottom: 24px;
}

.headerIcon {
  color: @primary-700;
  margin-right: 6px;
  align-self: center;
}

.productName {
  color: @primary-700;
  margin-right: 6px;
}

.creditContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.creditType {
  width: 182px;
  display: flex;
  flex-direction: column;
  margin: 12px 12px 0 12px;
}

.pricingTable {
  display: grid;
  grid-template-columns:
    minmax(20%, 26%) [charges]
    minmax(20%, 26%) [type]
    minmax(284px, max-content) [rate]
    minmax(163px, max-content) [options]
    auto;
  grid-template-rows: 32px;
  align-items: center;
  > div {
    padding: 8px 40px 8px 8px;
    &:nth-child(4n - 2) {
      padding-left: 8px;
    }
  }
  padding: 12px 0 12px 12px;
}
.pricingTableHeader {
  background: @grey-50;
  height: 32px;
  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}
.pricingTableHeaderText {
  color: @grey-800;
}
.cell {
  border-bottom: 1px solid @grey-100;
  height: 100%;
  display: flex;
  align-items: center;
  &:nth-last-child(1),
  &:nth-last-child(2),
  &:nth-last-child(3),
  &:nth-last-child(4),
  &:nth-last-child(5) {
    border: none;
  }
}
.chargeListTooltip {
  padding-left: 12px;
}
.feeType {
  color: @grey-600;
}
.tierCell {
  background: @grey-50;
  min-height: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.tierText {
  color: @grey-600;
}
.addTier {
  margin-top: 4px;
  align-self: flex-end;
}
