@import "style/colors.less";

.titleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.error {
  color: @error-600;
}

.gray {
  label {
    color: @grey-600;
  }
}

.datePickers {
  display: flex;
  justify-content: space-between;
  width: 182px;
}

.actions {
  margin-top: auto;
  align-self: flex-end;
}
