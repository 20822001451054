.menu {
  min-width: 100%;
  width: inherit !important;
  right: 0;
  display: flex;

  .menuList {
    min-width: 275px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .customDatePicker {
    margin: 12px;
  }
}
