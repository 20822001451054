.popup {
  width: 500px;
}

.description {
  margin-bottom: 16px;
}

.blockSizeText {
  margin-bottom: 8px;
}

.blockSizeInput {
  width: 208px;
  margin-bottom: 24px;
}

.roundingBehaviorSelector {
  display: flex;
  gap: 14px;
}
