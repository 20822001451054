@import "style/colors.less";

.salesforceConnectionModal {
  width: 400px;

  .description {
    color: @grey-700;
  }
}

.salesforceAccountIdInput {
  margin-top: 10px;
}