@import "../../../style/typography.less";

.toggleButtons {
  display: flex;
  margin-bottom: 17px;

  .button {
    margin-right: 13px;
    background-color: @white;
    color: @grey-600;
    text-align: left;
    line-height: 16px;
    padding: 9px 12px;
    height: auto;
    width: auto;

    .mainLabel {
      font-size: 14px;
      font-weight: 600;
      text-transform: none;
      line-height: 16px;
    }
    .subLabel {
      font-size: 12px;
      font-weight: 500;
      text-transform: none;
      line-height: 16px;
    }

    &.disabled {
      color: @grey-200;
      &:hover {
        color: @grey-200;
        background-color: @white;
      }
    }

    &.selected {
      background-color: rgb(var(--color-primary-600));
      color: @white;

      &.disabled {
        background-color: @grey-200;
        &:hover {
          color: @white;
          background-color: @grey-200;
        }
        &:focus {
          background-color: @grey-200;
        }
      }
    }

    &:hover {
      color: @white;
      background-color: rgb(var(--color-primary-700));
    }
    &:focus {
      background-color: rgb(var(--color-primary-600));
      color: @white;
    }
    &:active {
      background-color: rgb(var(--color-primary-600));
    }

    &.light {
      &.selected {
        background-color: rgb(var(--color-primary-50));
        color: rgb(var(--color-primary-600));
      }

      &:hover {
        background-color: rgb(var(--color-primary-50));
        color: rgb(var(--color-primary-600));
      }

      &:active {
        background-color: rgb(var(--color-primary-50));
        color: rgb(var(--color-primary-600));
      }
    }
  }

  .greyButton {
    background-color: @grey-50;
  }
}
