@import "style/colors.less";

.warningDot {
  height: 7px;
  width: 7px;
  background-color: @warning-300;
  vertical-align: middle;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 1px;
}
