@import "../../../../../../../../style/colors.less";

.webhookPopup {
  // Wider popup to fit the whole notification body.
  width: fit-content;
}

.payloadBlock {
  margin: 8px;
  padding: 8px;
  background-color: @grey-900;
  color: @primary-200;
}
