@import "../../../style/animation.less";
@import "../../../style/border-radius.less";
@import "../../../style/typography.less";
.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;

  .tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    text-decoration: none;

    .micro-interaction(background-color, color;);
    .tabName {
      .micro-interaction(background-color, color;);
      .inter-medium();
      font-size: 14px;
      cursor: pointer;
      text-decoration: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: unset;
      border: none;
    }
    .tabName:focus {
      outline: none;
    }
    .tabIcon {
      margin-right: 4px;
    }
  }

  &.primary {
    height: 56px;

    .tab {
      margin-right: 24px;
      .tabName {
        color: @grey-500;
      }

      &.active {
        padding-top: 2px;

        border-bottom: 2px solid @primary-500;
        .tabName {
          color: @primary-600;
        }
      }

      .tabName:focus {
        color: @primary-600;
        background-color: @primary-50;
        .border-radius-large();
      }
    }
  }
  &.secondary {
    .tab {
      margin-right: 8px;
      .border-radius-large();
      color: @grey-500;
      padding: 8px 12px;

      .tabName {
        background: none;
        border: none;
        color: @grey-500;
      }

      &:hover .tabName {
        color: @grey-600;
      }

      &.active {
        background-color: @primary-50;

        .tabName,
        .tabName * {
          color: @primary-600;
        }

        &:hover {
          .tabName,
          .tabName * {
            color: @primary-600;
          }
        }
      }

      .tabName:focus {
        color: @primary-600;
        background-color: @primary-50;
      }
    }
  }
}
