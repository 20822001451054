.creditTypeContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.creditType {
  width: 182px;
  display: flex;
  flex-direction: column;
  margin: 12px 12px 0 0;
}

.priceInputContainer {
  margin-bottom: 28px;

  .priceInput {
    width: 208px;
  }
}
