@import "../../../style/animation.less";
@import "../../../style/colors.less";
@import "../../../style/border-radius.less";
@import "../../../style/typography.less";
@import "../../../style/layout.less";
@import "../../../style/shadows.less";

.cards {
  padding: 12px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-auto-rows: 1fr;
  gap: 12px;

  &.gap24 {
    padding: 24px 0;
    gap: 24px;
  }

  .card {
    background: @white;
    color: @font-color;
    border: 1px solid @grey-100;
    background-color: @background;
    .border-radius-large();
    padding: 12px;
    display: flex;
    flex-direction: column;

    &.clickable {
      cursor: pointer;
      text-decoration: none;
      .micro-interaction(box-shadow;);
      &:hover {
        .dropdown-shadow();

        .title {
          color: @primary-600;
        }
      }
    }

    .header {
      .title {
        .micro-interaction(color;);
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .subtitle {
      font-size: 12px;
      line-height: 20px;
      .inter-regular();
      color: @grey-700;
    }

    .content {
      position: relative;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      font-size: 12px;
      line-height: 20px;
      .inter-regular();
      color: @font-color;

      .statistics {
        margin-top: auto;
        padding-top: 4px;

        > * {
          margin-right: 12px;
        }
      }

      .tooltip {
        color: @grey-600;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    .footer {
      margin-top: 8px;
      .fullBleed();
      border-top: 1px solid @grey-100;
      padding-top: 8px;
      margin-bottom: -4px;
    }
  }
}
