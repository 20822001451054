@import "../../../style/colors.less";
@import "../../../style/shadows.less";
@import "../../../style/typography.less";
@import "../../../style/border-radius.less";

.datepicker {
  :global(.react-datepicker) {
    border: none;
  }

  :global(.react-datepicker__header--custom) {
    padding: 0;
    background: none;
    border: none;

    // For some reason the monthClassName prop has no effect, so just override the style here 🙃
    .react-datepicker__month {
      margin: 0;
    }
  }

  .header {
    font-size: 12px;
    line-height: 24px;
    text-align: left;
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .monthButtons {
      display: flex;
  
      .monthButton {
        text-decoration: none;
        cursor: pointer;
        min-width: unset;
  
        &:nth-child(2) {
          width: 10px;
          color: @grey-400;
        }
      }
    }
  }
  
  .day {
    .inter-medium();
    color: @grey-900;
  
    &:hover {
      background: @grey-50;
      color: @grey-900;
    }
    &:global(.react-datepicker__day--outside-month) {
      color: @grey-400;
    }
  
    &:global(.react-datepicker__day--today) {
      color: @primary-600;
    }

    // The order of the following 3 selector blocks is important, as the latter overide the prior
    &:global(.react-datepicker__day--keyboard-selected) {
      background: inherit;
      outline: none;
    }

    &:global(.react-datepicker__day--in-range), &:global(.react-datepicker__day--in-selecting-range) {
      background: @primary-100;
      color: @black;
      border-radius: 0;
      margin: 0;
      padding: 0 2px;
      width: 36px;

      &:first-child {
        width: 32px;
      }

      &:last-child {
        width: 32px;
      }
    }
  
    &:global(.react-datepicker__day--selected),
    &:global(.react-datepicker__day--range-start),
    &:global(.react-datepicker__day--selecting-range-start),
    &:global(.react-datepicker__day--range-end),
    &:global(.react-datepicker__day--selecting-range-end) {
      border-radius: 4px;
      background: @primary-500;
      color: @white;
      outline: none;
    }
    // End order dependent blocks
  
    &:global(.react-datepicker__day--disabled) {
      background: unset;
      color: @grey-600;
    }
  }
  
  .weekdayLabel {
    .inter-medium();
    color: @grey-600;
  }
  
  .day,
  .weekdayLabel {
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    margin: 0 2px;
    font-size: 12px;
  
    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
}
