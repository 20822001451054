@import "style/colors.less";

.productDetails {
  width: 440px;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}
.pricingFactorMetric {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.pricingFactorIcon {
  margin-right: 4px;
}

.charges,
.plans {
  margin-top: 18px;
}

.badge {
  text-transform: uppercase;
}
.badge.compositeBadge {
  background-color: @success-700;
}
