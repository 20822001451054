@import "style/colors.less";

.leftPane {
  width: 700px;
  min-width: 700px;
  padding-right: 104px;
}

.divider {
  width: 1px;
  border-left: 1px solid @grey-200;
}

.rightPane {
  padding: 0 20px;
  min-width: 500px;
  flex: 1;
}

.singlePane {
  width: 600px;
  padding-right: 20px;
}

.fullscreen {
  width: 100%;

  .pageHeader {
    width: 520px;
  }
}
