@import "style/colors.less";

.planLifecycle {
  .heading {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .invoiceSkeletons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .invoiceSkeleton {
    margin-right: 15px;
  }

  .emptyState {
    margin-top: 120px;
    color: @grey-400;
    text-align: center;
  }
}

.ExampleServicePeriods {
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;

  .invoice {
    width: 116px;
    height: 155px;
    margin-right: 24px;
    margin-bottom: 24px;
    position: relative;

    svg {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
    .faded {
      opacity: 0.4;
    }

    .content {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      text-align: center;
      padding: 24px 8px 8px 8px;

      .pricingRamp,
      .trialInvoice {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        bottom: 0;
        padding-bottom: 12px;
      }
    }
  }
}

.InvoiceIllustration {
  .foreground {
    fill: @grey-50;
  }

  .background {
    fill: @grey-300;
  }
}

each(@themes, {
  @foreground-color: '@{value}-100';
  @background-color: '@{value}-300';
  .InvoiceIllustration.@{value} {
    .foreground {
      fill: @@foreground-color;
    }

    .background {
      fill: @@background-color;
    }
  }
});

.termPreview {
  .header {
    color: @grey-900;
    margin-bottom: 12px;
  }
  .planDetails {
    background: @white;
    min-height: 500px;
  }
  .planName {
    padding: 8px 0;
    margin-left: 15px;
    border-bottom: 1px solid @grey-100;
  }
  .planContent {
    margin: 10px 12px;
  }
  .planStatistics {
    margin: 20px 0;
  }
}

.blank {
  width: 92px;
  height: 8px;
  background: @grey-50;
  margin-top: 8px;
}
