@import "../../../../../../style/typography.less";

.content {
  .alert {
    display: flex;
    flex-direction: column;
    padding-bottom: 12px;
    border-bottom: 1px solid @grey-100;

    .alertDetails {
      display: flex;
      margin-top: 12px;

      > div {
        display: flex;
        flex-direction: column;
        margin-right: 36px;
      }

      > div:last-of-type {
        margin-right: 0;
      }

      .policyBadge {
        color: @primary-600;
        .inter-regular();
      }
    }
  }

  .alertCustomers {
    padding: 12px 0 12px 0;

    .customerInAlarmCount {
      color: @grey-700;
    }
  }
}
.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.statusRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .alarmBadge {
    background-color: @badge-2;
    color: @white;
    margin-right: 18px;
  }
}

.shrinkColumn {
  width: 164px;
}

.shrinkHeader {
  white-space: nowrap;
}

.shrinkCell {
  width: 1px;
  white-space: nowrap;
}

.emptyState {
  margin-top: 100px;
}

.searchBar {
  width: 208px;
}
