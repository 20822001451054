@import "style/colors.less";
@import "../../../style/border-radius.less";

.tipBox {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  background-color: @primary-50;
  border: 1px solid @primary-400;
  .border-radius-large();
  padding: 10px 14px 0 14px;

  .left {
    width: 40px;
    flex-grow: 0;
  }

  .tipIcon {
    color: @primary-500;
  }

  .right {
    margin-left: 10px;
  }

  .tipTitle {
    color: @primary-900;
    margin-bottom: 4px;
  }

  .tipBody {
    > p {
      color: @primary-700;
    }
  }
}

.floating {
  position: absolute;
  margin: 0 12px;
}
