@import "style/border-radius.less";
@import "style/colors.less";

.selectedCustomer {
  padding: 8px 0 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: @white;
  border: 1px solid @grey-100;
  .border-radius-large();
  .createdAt {
    display: flex;
    align-items: center;
  }
}
