.dropdownContainer {
  width: 204px;
}

.rampContainer {
  margin: 17px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .rampInput {
    width: 183px;
  }

  .inputUnit {
    margin-bottom: 6px;
    margin-left: 11px;
  }
}
