@import "style/colors.less";

.sidebarSection {
  padding: 6px 10px;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  &.selected {
    padding: 10px;
    background-color: @white;
    border-radius: 4px;
  }

  &.completed {
    .icon {
      color: @success-600;
    }
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    top: 3px;
    color: @grey-600;
  }

  .customIcon.icon {
    background: @primary-500;
    color: @white;
    width: 16px;
    height: 16px;
    border-radius: 40px;
    padding: 4px;
    top: 0;
  }
}

.sidebarGroup {
  margin-left: 4px;
}

.sidebarGroupLabel {
  color: @grey-600;
  cursor: pointer;
  padding: 2px 0;

  &.selected {
    color: @primary-600;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.subStepTitle {
  color: @grey-600;
  padding: 4px 0;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  .link {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 8px;
    height: 8px;
    margin-left: 2px;
    margin-right: 0px;
    opacity: 0;
    top: 0px;
  }

  .label {
    margin-left: 12px;
    display: inline-block;
  }

  &.selected {
    color: @primary-600;
    .icon {
      color: @primary-600;
      opacity: 1;
    }
  }
}

.sidebar {
  border-right: 1px solid @grey-200;
  width: 236px;
  min-width: 236px;
  padding: 10px;
  height: 100%;
  overflow: hidden;

  .yourProgress {
    margin-bottom: 8px;
  }

  .logo {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 40px;
    display: block;
  }
}
