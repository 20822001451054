@background: #fff;

@primary-900: #022660;
@primary-800: #02388f;
@primary-700: #034bbf;
@primary-600: #046eef;
@primary-500: #007eff;
@primary-400: #61a7fc;
@primary-300: #96c4fd;
@primary-200: #cae2fe;
@primary-100: #d9eafe;
@primary-50: #f1f7fe;

@grey-900: #1b1d21;
@grey-800: #363b41;
@grey-700: #515862;
@grey-600: #6c7682;
@grey-500: #808995;
@grey-400: #98a1ab;
@grey-300: #aab0b9;
@grey-200: #bac2cc;
@grey-100: #dce0e6;
@grey-50: #f2f3f5;

@success-900: #062814;
@success-800: #0c5027;
@success-700: #11783b;
@success-600: #17a04e;
@success-500: #1ab759;
@success-400: #52d888;
@success-300: #79e8a5;
@success-200: #99f0bd;
@success-100: #c0f6d6;
@success-50: #ebfaf1;

@warning-900: #331d01;
@warning-800: #663902;
@warning-700: #995603;
@warning-600: #cc7204;
@warning-500: #e89806;
@warning-400: #f9ad22;
@warning-300: #fabd4e;
@warning-200: #fcce7a;
@warning-100: #fddea7;
@warning-50: #fef6e7;

@error-900: #360909;
@error-800: #6b1212;
@error-700: #a11b1b;
@error-600: #d62424;
@error-500: #e93c3c;
@error-400: #ed5d5d;
@error-300: #f07d7d;
@error-200: #f49e9e;
@error-100: #f8bebe;
@error-50: #fcebeb;

@white: #ffffff;
@black: #000000;

@themes: primary, grey, success, error, warning;

@badge-1: #22b796;
@badge-2: #5f5dda;
@badge-3: #e9884d;
@badge-4: #e2be3a;
