@import "style/colors.less";

.footer {
  background-color: @white;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.back {
  margin-right: 8px;
}
