@import "../../../style/colors.less";

.gray {
  label {
    color: @grey-600;
  }
}

.datePickers {
  display: flex;
  justify-content: space-between;
  width: 182px;
}
