@import "style/typography.less";
@import "style/border-radius.less";

.usageCell,
.flatFeeCell,
.compositeChargeCell,
.tierResetFrequencyCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tieredFlat {
  margin-left: 28px;
}

.usageInput {
  width: 280px;
}

.tieredToggle {
  margin-left: 16px;
}

.tooltip {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.tieredCell {
  .tierMinInput {
    width: 128px;
    margin-right: 28px;
    &.disabled {
      span,
      input {
        color: @grey-200;
        font-weight: 400;
      }
    }
    span,
    input {
      color: @font-color;
      font-weight: 400;
    }
    input {
      background: white;
    }
  }
  .v1TierInput {
    input {
      padding-left: 74px;
    }
  }
}

.flatFeeCell,
.compositeChargeCell {
  .quantityX {
    .inter-regular();
    color: @grey-600;
    margin: 2px 8px 4px 8px;
    width: 12px;
  }

  .quantityInput {
    width: 128px;

    input {
      padding-left: 68px;
    }

    span {
      color: @grey-600;
      .inter-regular();
    }

    &.disabled {
      input,
      span {
        color: @grey-300;
      }
    }
  }

  .proratedToggle {
    margin-left: 16px;
    min-width: 121px;
  }
}

.minimumInput {
  width: 130px;
}

.icon {
  margin-left: 4px;
  min-width: 16px;
  min-height: 16px;
  display: inline-flex;
}
