@import "style/typography.less";

.positive {
  color: @success-600;
  .inter-semibold();
}

.negative {
  color: @error-600;
  .inter-semibold();
}

.voided {
  color: @grey-300;
  .inter-semibold();
}

.expiryBadge {
  color: @error-600;
  .ledgerBadgeCaption {
    color: @error-600;
  }
  .ledgerBadgeDate {
    color: @error-600;
  }
}

.pendingBadge {
  color: @warning-600;
  .ledgerBadgeCaption {
    color: @warning-600;
  }
  .ledgerBadgeDate {
    color: @warning-600;
  }
}

.voidedBadge {
  color: @warning-600;
  .ledgerBadgeCaption {
    color: @warning-600;
  }
  .ledgerBadgeDate {
    color: @warning-600;
  }
}

.ledgerBadge {
  display: flex;
  align-items: center;

  .ledgerBadgeCaption {
    .inter-bold();
  }

  .ledgerBadgeDate {
    .inter-regular();
  }
}
