@import "style/colors.less";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left {
  width: 520px;
}

.right {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.productCountLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.productCountIcon {
  margin-right: 4px;
}

.searchBar {
  width: 250px;
}
.selected {
  color: @primary-600;
}

.productCard {
  &:hover {
    color: @primary-600;
    & .previewButton {
      display: block;
      margin-top: 4px;
      margin-right: 4px;
    }
  }
  .previewButton {
    display: none;
  }
  &.selected {
    color: @primary-600;
    background-color: @primary-50;

    h6 {
      color: @primary-600;
    }
  }
  .icon {
    height: 32px;
    padding: 4px 0;
    color: @grey-600;
    &.selected {
      color: @primary-600;
    }
  }
}
