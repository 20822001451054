@import "style/colors.less";

.gray {
  label {
    color: @grey-600;
  }
}

.priorityInput {
  width: 182px;
  margin-bottom: 4px;
}

.priorities {
  .priority {
    border: 1px solid @grey-100;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px 8px 12px;
    margin-bottom: 8px;
    width: 100%;

    .priorityLeft {
      display: flex;
      align-items: center;
    }

    .priorityLabel {
      margin-right: 10px;
      width: 70px;
      text-transform: uppercase;
    }

    .new {
      color: @primary-600;
    }

    .notMovable {
      color: @grey-600;
    }
  }
}
