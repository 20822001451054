@import "style/colors.less";

.container {
  display: flex;
  background: @grey-50;
  height: 100%;

  .main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;

    .headerActions {
      display: flex;
      align-items: center;

      .closeButton {
        margin-left: 12px;
      }
    }

    .stepContainer {
      flex-grow: 1;
      position: relative;
      overflow: auto;

      .stepContent {
        overflow: visible;
        margin: 12px 12px 40px 12px;

        > div {
          display: flex;
        }
      }
    }
  }
}

.loadingText {
  display: flex;
  align-items: center;
  font-size: 12px;

  .text {
    margin-left: 10px;
  }
}
