@import "style/layout.less";
@import "style/colors.less";
@import "style/typography.less";

.collapsibleBorder(@width, @color) {
  box-shadow: @width 0 0 0 @color, 0 @width 0 0 @color, @width @width 0 0 @color,
    @width 0 0 0 @color inset, 0 @width 0 0 @color inset;
}

.subHeading {
  .fullBleed();
}

.container {
  margin-left: -13px;
  margin-right: -12px;
  margin-top: -13px;
}

.metric {
  padding: 12px;
  .collapsibleBorder(1px, @grey-100);
  width: 50%;
  float: left;

  &.fullWidthMetric {
    width: 100%;
  }

  .chart {
    height: 240px;
  }
}

.skeletonChart {
  margin-top: 16px;
}
