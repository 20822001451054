.pairedDateInputs {
  display: flex;
  flex-direction: row;
}

.dateInputStack {
  display: flex;
  flex-direction: column;
  width: 208px;
  margin-right: 20px;
}
