.applyRampsToggle {
  margin-bottom: 22px;
}

.overageRampContainer {
  margin-bottom: 50px;

  .rampTitle {
    margin-bottom: 12px;
  }

  .overagePriceInputContainer {
    margin-bottom: 31px;
  }
}
