@import "../../../../../../../style/typography.less";
@import "../../../../../../../style/colors.less";

.popover {
    width: 498px !important;
}

.formGroup {
    padding-top: 12px;
    padding-bottom: 12px;
}

.fieldSelect {
    width: 300px !important;
    margin-left: 12px;
}