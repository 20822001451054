@import "style/animation.less";
@import "style/border-radius.less";
@import "style/colors.less";
@import "style/layout.less";
@import "style/typography.less";
@import "style/shadows.less";

.container {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-template-rows: 1fr;
  margin: -12px -12px 0 -12px;
  flex: 1;
}

.leftColumn {
  grid-column: col-start / span 4;
  grid-row: 1;
  border-right: 1px solid @grey-100;
  max-width: 100%;
}

.rightColumn {
  grid-column: col-start 5 / span 8;
  grid-row: 1;
  max-width: 100%;
}

.row {
  border-bottom: 1px solid @grey-100;
  padding: 12px;
  .micro-interaction(background-color;);
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.datesRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: wrap;
}

.dateLabel {
  .header();
  .icon {
    margin-right: 4px;
  }
}

.planId,
.dateLabel,
.dateLabel > h6 {
  color: @grey-700;
}

.activeStart,
.activeStart > h6 {
  color: @primary-600;
}

.planRow {
  .border-radius-large();
  margin: 12px;
  .micro-interaction(box-shadow, background-color;);
  border: 1px solid @grey-100;
  cursor: pointer;

  &.selectedPlan,
  &:hover {
    background-color: @primary-50;
    border-bottom: 1px solid @primary-200;
  }

  &.selectedPlan {
    .planTitle {
      color: @primary-600;
    }
  }
}

.dropShadowHover {
  &:hover {
    .dropdown-shadow();
  }
}

.planPanel {
  padding: 12px;
}

.planPanelHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.planName {
  display: flex;
  flex-direction: row;
  align-items: center;

  .stateBadge {
    margin-left: 8px;
    &.trialBadge {
      background: @badge-2;
    }
  }
}

.planDescription {
  margin: 0;
}

.rightPaneHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.rightPaneActions {
  margin-top: auto;
  align-self: flex-end;
}

.trialSpecSection {
  margin-top: 24px;

  & label {
    margin-top: 8px;
  }

  & button {
    margin-top: 24px;
  }
}
