@import "style/colors.less";

.titleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.error {
  color: @error-600;
}

.gray {
  label {
    color: @grey-600;
  }
}

.grantName {
  margin-bottom: 8px;
}

.productSpecificCredit {
  display: flex;
  align-items: center;
}
.productId {
  display: flex;
  align-items: center;
  .productIdLabel {
    color: @grey-600;
  }
}

.pricingUnit {
  width: 208px;
  margin-bottom: 8px;
}

.creditGranted {
  margin-bottom: 8px;
}

.amountPaid {
  display: flex;
}

.amountPaidInput {
  flex-grow: 0.7;
  margin: 0 4px 0 0;
}

.amountPaidPricingUnit {
  margin-top: 18px;
  flex-grow: 0.3;
}

.costBasis {
  margin-bottom: 36px;
  color: @grey-600;
}

.creditPurchaseText {
  display: flex;
}

.billInArrearsText {
  margin-bottom: 24px;
}

.datePickers {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  .effectiveAt,
  .expiresBefore {
    width: 182px;
  }
}

.rolloverSection {
  margin-bottom: 24px;

  .rolloverInputFields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .rolloverPercent,
    .expiresAfter {
      width: 182px;
    }
  }
}
