@import "../../../../../../../../style/colors.less";

.webhookPopup {
  // Wider popup to fit the whole webhook secret.
  width: 600px;
}

.nameInput {
  margin-bottom: 8px;
}

.secretCopyBox {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.webhookCopyValue {
  width: 100%;
}

.secretCopyButton {
  margin-bottom: 4px;
}

.infoIcon {
  margin-right: 4px;
  color: @primary-600;
}
