@import "style/border-radius.less";
@import "style/colors.less";

.customerImpact {
  .border-radius-large();
  border: 1px solid @grey-100;
  background: @white;
  padding: 0 24px 12px 24px;
}

.customerImpactTooLarge {
  margin-top: 100px;
}

.changed {
  color: @warning-600;
}
