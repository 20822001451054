@import "../../../style/typography.less";

.popover {
  width: 800px !important;

  .mappingTitle {
    padding-left: 10px;

    .mappingLogo {
      padding-right: 5px;
    }
  }

  .formGroup {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .mappingRow {
    .select {
      width: 170px !important;
      margin-left: 10px;
      color: @grey-600;
    }

    :global(.select__control--is-disabled) {
      :global(.select__single-value) {
        color: black !important;
      }

      :global(.select__indicators) {
        display: none !important;
      }
    }

    .vendorKeyFreeform {
      /*
                make disabled (unchangable) vendor fields to look enabled, hide select control arrow
            */
      :global(input) {
        &:not([disabled]) {
          color: black !important;
        }
        margin-left: 0 !important;
        margin-top: -2px !important;
      }
    }

    .spacer {
      padding: 10px;
    }

    .deleteMapping {
      margin-top: 12px;
      color: @grey-600;

      &:hover {
        color: @grey-300;
      }
    }
  }
}
